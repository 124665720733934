<template>
  <div>
    <b-row v-if="!noHeader">
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Придумайте и подтвердите пароль
        </h5>
        <small class="text-muted">
          Ваш пароль должен содержать не менее <span class="text-body">6</span> символов
        </small>
      </b-col>
    </b-row>

    <b-form
      class="auth-register-form"
      @submit.prevent="submit"
    >
      <b-row>
        <b-col
          cols="12"
          :lg="column ? 12 : 6"
        >
          <b-form-group label="Пароль">
            <b-form-input
              ref="password"
              v-model="data.password"
              v-validate="'required|min:6'"
              name="password"
              :state="errors.first('password') ? false:null"
              placeholder="Введите пароль"
              autocomplete="off"
              type="password"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors.first('password') }}</small>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          :lg="column ? 12 : 6"
        >
          <b-form-group label="Повторите пароль">
            <b-form-input
              v-model="data.password_repeat"
              v-validate="'required|confirmed:password'"
              name="password_repeat"
              :state="errors.first('password_repeat') ? false:null"
              placeholder="Введите ваш пароль еще раз"
              autocomplete="off"
              type="password"
              data-vv-validate-on="blur"
              data-vv-as="password"
            />
            <small class="text-danger">{{ errors.first('password_repeat') }}</small>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

export default {
  name: 'PasswordCheck',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    column: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        password: null,
        password_repeat: null,
      },
    }
  },
  computed: {
    dict() {
      return {
        custom: {
          password: {
            required: 'Необходимо придумать пароль',
            min: 'Пароль должен содержать не менее 6 символов',
          },
          password_repeat: {
            required: 'Необходимо подтвердить ваш пароль',
            confirmed: 'Пароли не совпадают',
          },
        },
      }
    },
  },
  watch: {
    'data.role': {
      handler() {
        this.data.name = null
      },
    },
  },
  mounted() {
    this.$validator.localize('ru', this.dict)
  },
  methods: {
    submit() {
      return this.$validator.validate().then(valid => {
        if (valid) {
          // do stuff if not valid.
          return this.data
        }
        return null
      })
    },
  },
}
</script>
