<template>
  <div>
    <b-form
      class="auth-register-form"
      @submit.prevent="submit"
    >
      <b-row class="mt-1">
        <b-col
          :cols="fullWidth ? 12 : 8"
          class="mx-auto"
        >
          <b-overlay
            :show="codeApproving || codeRequesting"
            spinner-variant="primary"
            spinner-type="grow"
          >
            <!-- PHONE IS TAKEN -->
            <b-alert
              v-if="phoneIsTaken"
              variant="danger"
              show
            >
              <h4 class="alert-heading">
                Данный номер уже зарегистрирован
              </h4>
              <div class="alert-body">
                <p>Возможно, вы уже регистрировались на платформе. Попробуйте
                  <b-link :to="{name:'auth.login'}">
                    войти
                  </b-link>.
                  Если вы уверены, что никогда не регистрировались, но номер принадлежит вам, пожалуйста, обратитесь в
                  <b-link :to="{name:'auth.login'}">
                    поддержку
                  </b-link>.
                </p>
              </div>
            </b-alert>

            <!-- CODE APPROVED -->
            <b-alert
              v-else-if="codeApproved"
              variant="success"
              show
            >
              <h4 class="alert-heading">
                Подтверждение номера
              </h4>
              <div class="alert-body">
                <div class="text-center">
                  <feather-icon
                    size="32"
                    class="my-1"
                    icon="CheckCircleIcon"
                  />
                  <h4 class="text-success">
                    Номер телефона верифицирован
                  </h4>
                  <div class="text-success mb-1">
                    Вы завершили проверку и можете продолжить регистрацию
                  </div>
                </div>
              </div>
            </b-alert>

            <b-alert
              v-else
              :variant="codeWrong ? 'danger' : 'primary'"
              show
            >
              <h4 class="alert-heading">
                Подтверждение номера
              </h4>
              <div class="alert-body">

                <div v-if="!checkExecuted">
                  <template v-if="selfCall">
                    <div>
                      <div class="text-center my-1">
                        <a
                          class="font-weight-bolder font-large-1"
                          href="tel:+79955775109"
                        >
                          +7 (995) 577-51-09
                        </a>
                      </div>
                      <p>Для подтверждения номера телефона, совершите звонок на указанный выше номер.</p>
                      <p>Дозвон необходимо сделать с номера телефона, который вы указали раннее <span v-if="phone">(+{{ phone }})</span>.</p>
                      <p>Система отклонит вызов и Вы увидите подтверджение.</p>
                    </div>
                    <b-button
                      class="mt-1"
                      variant="primary"
                      block
                      href="tel:+79955775109"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="PhoneOutgoingIcon"
                      />
                      <span>Позвонить</span>
                    </b-button>
                  </template>
                  <template v-else>
                    <div>
                      На указанный Вами номер <span v-if="phone">+{{ phone }}</span> будет совершен проверочный звонок или отправлено смс с кодом.
                      Введите код из смс или 4 последние цифры номера, с которого поступит звонок
                    </div>
                    <b-button
                      class="mt-1"
                      variant="primary"
                      block
                      @click="submit()"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="PhoneIncomingIcon"
                      />
                      <span>Начать</span>
                    </b-button>
                  </template>
                </div>
                <div v-else>
                  <template v-if="selfCall && codeWrong">
                    <h4 class="text-danger">
                      Ошибка подтверждения.
                    </h4>
                    <div>
                      {{ errorMessage || 'Произошла неизвестная ошибка. Пожалуйста, обратитесь к администратору' }} или восстановите пароль
                    </div>
                    <b-button
                      class="mt-1"
                      variant="primary"
                      block
                      @click="checkExecuted = false; codeWrong = false;"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="PhoneOutgoingIcon"
                      />
                      Повторить звонок
                    </b-button>

                    <div class="mt-1 text-center">
                      <b-link :to="{ name: 'auth.password_reset' }">
                        Забыли пароль?
                      </b-link>
                    </div>

                  </template>
                  <template v-else>
                    <h4
                      v-if="codeWrong"
                      class="text-danger"
                    >
                      Вы ввели неверный код.
                    </h4>
                    <div>
                      Введите
                      <span class="font-weight-bolder">код из смс</span> или
                      <span class="font-weight-bolder">4 последние цифры</span>
                      номера, с которого поступил звонок
                    </div>

                    <div
                      :key="resetCode"
                      class="my-1 d-flex justify-content-center flex-fill"
                    >
                      <CodeInput
                        :auto-focus="true"
                        class="verify-input"
                        :class="codeWrong ? 'danger' : 'primary'"
                        :fields="4"
                        @complete="codeEntered"
                      />
                    </div>

                    <div class="pt-1">
                      <p v-if="timeRemaining > 0">
                        Вы можете запросить повторный звонок или смс с кодом через {{ timeRemaining }} сек.
                      </p>
                      <p v-else>
                        Вы можете запросить повторный звонок или смс с кодом
                      </p>
                    </div>

                    <b-button
                      class="mt-1"
                      variant="outline-primary"
                      block
                      :disabled="timeRemaining > 0"
                      @click="checkExecuted = false; codeWrong = false;"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="PhoneIncomingIcon"
                      />
                      Повторить звонок
                    </b-button>
                    <b-button
                      class="mt-1"
                      variant="outline-primary"
                      block
                      :disabled="timeRemaining > 0"
                      @click="sendSms"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="MessageSquareIcon"
                      />
                      Отправить смс
                    </b-button>
                  </template>
                </div>
              </div>
            </b-alert>

          </b-overlay>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BAlert, BOverlay, BLink,
} from 'bootstrap-vue'
import CodeInput from 'vue-verification-code-input'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PhoneCheck',
  components: {
    CodeInput,
    BRow,
    BCol,
    BForm,
    BButton,
    BAlert,
    BOverlay,
    BLink,
  },
  props: {
    phone: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    codeApproved: {
      type: Boolean,
      required: true,
    },
    codeApproving: {
      type: Boolean,
      required: true,
    },
    codeWrong: {
      type: Boolean,
      required: true,
    },
    codeRequesting: {
      type: Boolean,
      required: true,
    },
    phoneIsTaken: {
      type: Boolean,
      required: true,
    },
    checkExecuted: {
      type: Boolean,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    selfCall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        code: null,
      },
      callInterval: null,
      timeRemaining: 60,
      callAgainButton: 0,
      resetCode: 0,
      phoneCheckSubscription: null,

      options: {
        phone: {
          numericOnly: true,
          delimiters: [' ', '(', ') ', ' - ', ' - '],
          blocks: [2, 0, 3, 3, 2, 2],
          prefix: '+7',
          noImmediatePrefix: true,
        },
        number: {
          numericOnly: true,
          blocks: [30],
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      websockets: 'websockets/get_client',
    }),
  },
  watch: {
    codeWrong() {
      this.data.code = null
      this.resetCode += 1
    },
    checkExecuted() {
      if (this.checkExecuted) {
        this.timeRemaining = 60
        this.callInterval = setInterval(() => {
          if (this.timeRemaining === 0) {
            clearInterval(this.callInterval)
            return
          }
          this.timeRemaining -= 1
        }, 1000)
      }
    },
    async phone() {
      if (!this.websockets) await this.initConnection()

      if (this.phone) {
        this.phoneCheckSubscription = this.websockets.subscribe(`auth.${this.phone}.accepted`, event => {
          const { data } = event
          switch (data.status) {
            case 'confirmed':
            case 'ok':
              this.$emit('onCodeApproved')
              break
            case 'error':
              this.$emit('onCodeError', data.message)
              break
            default:
          }
        })
      } else {
        this.unsubFromCheck()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.callInterval)
    this.unsubFromCheck()
  },
  mounted() {
    this.$validator.localize('ru')
  },
  methods: {
    ...mapActions({
      initConnection: 'websockets/initConnection',
    }),
    unsubFromCheck() {
      if (this.websockets && this.phoneCheckSubscription) this.phoneCheckSubscription.unsubscribe()
    },
    submit() {
      return this.$validator.validate().then(valid => {
        if (valid) {
          this.$emit('codeRequested')
        }
        return null
      })
    },
    sendSms() {
      return this.$validator.validate().then(valid => {
        if (valid) {
          this.$emit('smsCodeRequested')
        }
        return null
      })
    },
    codeEntered(code) {
      if (!this.data.code) {
        this.data.code = code
        this.checkCode()
      }
    },
    dropCode() {
      this.data.code = null
    },
    checkCode() {
      if (this.data.code) {
        this.$emit('checkCode', this.data.code)
      }
    },
  },
}
</script>
